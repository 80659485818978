import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import maxBy from 'lodash/maxBy';
import get from 'lodash/get';
import flatMap from 'lodash/flatMap';
import cloneDeep from 'lodash/cloneDeep';
import defaultsDeep from 'lodash/defaultsDeep';
import omit from 'lodash/omit';
import * as guideUtil from '@/utils/guides';
import * as statelessGuideUtil from '@/stateless-components/utils/guides';
import { addAppToEntityList, meetsMinimumAgentVersion } from '@/utils/apps';
import { MOBILE_PLATFORMS } from '@/stateless-components/utils/apps';
import { http } from '@pendo/http';
import { importGuideLocalizationFile, clearGuideTranslationStrings } from '@/utils/localization';
import {
    BuildingBlock,
    BuildingBlockLayouts,
    BuildingBlockMigrations,
    GuideLocalization
} from '@pendo/services/BuildingBlocks';
import { isCrossApp } from '@pendo/services/CrossAppGuides';
import { canCopyGuide, canCreateFromScratch } from '@/utils/guide-permissions';

export function getInitialState () {
    return {
        activeId: null,
        isFetching: false,
        isUpdating: false,
        map: {},
        canPreviewPublicGuide: false,
        canPreviewNonPublicGuide: false,
        guidesListUserSettings: null,
        previewLanguage: '',
        error: null
    };
}

export const state = getInitialState();

export const mutations = {
    setActiveById (state, { id }) {
        state.activeId = id;
    },
    setMap (state, { map }) {
        state.map = map;
    },
    setUpdate (state, { guide }) {
        Vue.set(state.map, guide.id, guide);
    },
    setDelete (state, { id }) {
        Vue.delete(state.map, id);
    },
    setFetching (state, { isFetching }) {
        state.isFetching = isFetching;
    },
    setUpdating (state, { isUpdating }) {
        state.isUpdating = isUpdating;
    },
    setBuildingBlocksContent (state, { guideId, stepId, buildingBlocks, dom }) {
        const guide = state.map[guideId];

        guide.steps.forEach((step, index) => {
            if (stepId === step.id) {
                Vue.set(step, 'buildingBlocks', buildingBlocks);
                Vue.set(step, 'dom', dom);
                Vue.set(guide.steps, index, step);
            }
        });

        Vue.set(state.map, guide.id, guide);
    },
    setGuidePreviewable (state, { canPreviewPublicGuide, canPreviewNonPublicGuide }) {
        state.canPreviewPublicGuide = canPreviewPublicGuide;
        state.canPreviewNonPublicGuide = canPreviewNonPublicGuide;
    },
    setPreviewLanguage (state, { previewLanguage }) {
        state.previewLanguage = previewLanguage;
    },
    reset (state) {
        Object.assign(state, getInitialState());
    },
    addGuideToMap (state, { guide }) {
        Vue.set(state.map, guide.id, guide);
    },
    setGuidesListUserSettings (state, { guidesListUserSettings }) {
        state.guidesListUserSettings = guidesListUserSettings;
    },
    setError (state, { error }) {
        state.error = error;
    }
};

export const actions = {
    async init ({ commit, dispatch }) {
        commit('setMap', { map: {} });
        await dispatch('fetch');
    },
    async create (
        { commit, dispatch, getters, rootGetters },
        { layout, appId, editorType, themeId, isAppFlutterCodeless }
    ) {
        const { buildingBlocks, hasCustomTheme, name, empty, badge, confirmation, attributes = {} } = layout;

        let guide;
        const guideAppId = appId || rootGetters['apps/activeId'];
        const isTraining = rootGetters['subscriptions/activeIsTrainingSubscription'];
        const mobileProps = {};
        const platform = get(rootGetters['apps/appById'](guideAppId), 'platform');
        if (MOBILE_PLATFORMS.includes(platform)) {
            const isMobileTooltip = BuildingBlock.findBlockByWidgetId(
                buildingBlocks,
                BuildingBlockLayouts.widgetIds.tooltip
            );

            if (layout.carouselId) {
                mobileProps.carouselId = layout.carouselId;
            }

            mobileProps.type = isMobileTooltip ? 'tooltip' : 'lightbox';
            mobileProps.isMobile = true;
            mobileProps.isAppFlutterCodeless = isAppFlutterCodeless;
        }

        const priority = getters.maxPriority + 1;
        commit('setUpdating', { isUpdating: true });
        try {
            if (empty) {
                guide = await guideUtil.createEmptyGuide({
                    ...mobileProps,
                    appId: guideAppId,
                    isTraining,
                    name,
                    priority,
                    editorType,
                    badge,
                    confirmation,
                    platform
                });
            } else {
                guide = await guideUtil.create({
                    ...mobileProps,
                    buildingBlocks,
                    name,
                    appId: guideAppId,
                    isTraining,
                    priority,
                    themeId: hasCustomTheme ? null : themeId || rootGetters['themes/defaultTheme'].id,
                    editorType,
                    platform
                });
            }
        } catch (err) {
            commit('setUpdating', { isUpdating: false });
            throw err;
        }

        guide.steps.forEach((step) => {
            step.buildingBlocks = JSON.parse(step.buildingBlocks);
            step.attributes.isAutoFocus = true;
        });
        // When a step created from a layout is added to a guide, the layout attributes are passed to the migration
        // function. Newly created layouts will have a shared services version that will be used to ensure the new
        // step receives all the necessary migrations. If the layout attributes have no shared service version
        // defined, the step/guide will be migrated according to a fixed version defined in the migrate function.
        BuildingBlockMigrations.migrate(guide, 'guide', attributes);
        BuildingBlockMigrations.migrate(guide, 'guidesUnversioned');
        guide.steps.forEach((step) => {
            step.buildingBlocks = JSON.stringify(step.buildingBlocks);
        });
        // isUpdating is set in the update action, no need to set false here
        await dispatch('update', { guide });
    },
    async clone ({ commit, dispatch }, { guide }) {
        const clonedGuide = await adoptV2Clone(guide.id);
        const clonedGuideName = `copy of ${clonedGuide.name}`;
        clonedGuide.name = clonedGuideName.length > 100 ? `${clonedGuideName.slice(0, 97)}...` : clonedGuideName;
        let activeGuideId = clonedGuide.id;

        await dispatch('update', { guide: clonedGuide })
            .catch((err) => {
                dispatch('delete', { guide: clonedGuide });
                activeGuideId = guide.id;
                commit('setUpdating', { isUpdating: false });
                throw err;
            })
            .finally(() => {
                commit('setActiveById', { id: activeGuideId });
            });
    },
    async fetch ({ commit, state, rootGetters }, { noCache = false } = {}) {
        if (state.isFetching) {
            return;
        }

        if (!isEmpty(state.map) && !noCache) {
            return;
        }

        commit('setFetching', { isFetching: true });
        const isActiveIsDigitalAdoption = rootGetters['subscriptions/activeIsDigitalAdoption'];
        const subscriptionGuideList = await fetchGuides(isActiveIsDigitalAdoption);
        const apps = rootGetters['apps/appMapForActiveSubscription'];

        const guidesForAvailableApps = subscriptionGuideList.slice().filter((guide) => {
            return !!apps[guide.appId];
        });

        commit('setMap', { map: keyBy(guidesForAvailableApps, 'id') });
        commit('setFetching', { isFetching: false });
    },
    async refreshGuide ({ commit, dispatch, state }, { id }) {
        if (!state.map[id]) {
            return;
        }

        const hasContent = !!get(state, `map['${id}'].steps[0].buildingBlocks`);

        commit('setFetching', { isFetching: true });

        const guide = await fetchGuide(id);

        commit('setUpdate', { guide });
        commit('setFetching', { isFetching: false });

        if (state.activeId === guide.id) {
            commit('setActiveById', { id: guide.id });
        }

        if (hasContent) {
            await dispatch('getBuildingBlocks', { guide });
        }
    },
    async update ({ commit, state }, { guide, setActive = true } = {}) {
        const combined = {
            ...state.map[guide.id],
            ...guide
        };
        commit('setUpdating', { isUpdating: true });

        combined.isMultiStep = combined.steps.length > 1;

        const updated = await updateGuide(combined);

        combined.steps.forEach((step, i) => {
            const { dom, buildingBlocks } = step;
            updated.steps[i].dom = dom;
            updated.steps[i].buildingBlocks = buildingBlocks;
        });

        commit('setUpdate', { guide: updated });
        if (setActive) {
            commit('setActiveById', { id: updated.id });
        }
        commit('setUpdating', { isUpdating: false });
    },
    async patch ({ commit, dispatch }, { guideId, props }) {
        // NFW: why do we only set RC errors for resource center patches on a patch used by all guide types?
        commit('resourceCenter/setError', { error: null }, { root: true });
        commit('setUpdating', { isUpdating: true });

        const body = Object.entries(props).map(([key, value]) => {
            return {
                op: 'replace',
                path: `/${key}`,
                value
            };
        });

        try {
            const guide = await patchGuide(guideId, { patch: body });
            commit('setUpdate', { guide });
            commit('setActiveById', { id: guide.id });
            await dispatch('getBuildingBlocks', { guide, noCache: true });
            commit('setUpdating', { isUpdating: false });
        } catch (error) {
            commit('resourceCenter/setError', { error }, { root: true });
            commit('setUpdating', { isUpdating: false });
        }
    },
    async clearViewData ({ commit }, { guide }) {
        commit('setFetching', { isFetching: true });

        await clearGuide(guide.id);

        commit('setFetching', { isFetching: false });
    },
    async delete ({ commit }, { guide }) {
        commit('setError', { error: null });
        commit('setFetching', { isFetching: true });

        let error;
        await deleteGuide(guide).catch((err) => {
            error = err;
        });
        if (!error) commit('setDelete', { id: guide.id });
        else commit('setError', { error });

        commit('setFetching', { isFetching: false });
    },
    async getBuildingBlocks ({ commit, state }, { guide, noCache = false }) {
        if (!state.map[guide.id]) {
            throw new Error(`Guide does not exist in map (${guide.id})`);
        }

        // eslint-disable-next-line no-param-reassign
        guide = state.map[guide.id];

        // dont make duplicate requests for this content unless we explicitly ask for it with `noCache = true`
        if (guide.steps.every((step) => step.buildingBlocks) && !noCache) {
            return;
        }

        const stepsContent = await Promise.all(guide.steps.map((step) => statelessGuideUtil.getBuildingBlocks(step)));

        stepsContent.forEach(({ buildingBlocks, dom }, index) => {
            commit('setBuildingBlocksContent', {
                buildingBlocks,
                dom,
                guideId: guide.id,
                stepId: guide.steps[index].id
            });
        });
    },
    async updateGuidePreviewable ({ commit }, { appId }) {
        commit('setGuidePreviewable', { canPreviewPublicGuide: false, canPreviewNonPublicGuide: false });

        const [canPreviewPublicGuide, canPreviewNonPublicGuide] = await Promise.all([
            meetsMinimumAgentVersion('2.15.13', appId),
            meetsMinimumAgentVersion('2.15.14', appId)
        ]);

        commit('setGuidePreviewable', { canPreviewPublicGuide, canPreviewNonPublicGuide });
    },
    async importLocalization ({ commit, dispatch }, { guideId, langCode, translations }) {
        commit('setUpdating', { isUpdating: true });

        try {
            await updateGuideTranslations(guideId, langCode, translations);
            await dispatch('refreshGuide', { id: guideId });
        } catch (err) {
            throw err;
        } finally {
            commit('setUpdating', { isUpdating: false });
        }
    },
    async importLocalizationFile ({ commit, state }, { guideId, langCode, file }) {
        commit('setUpdating', { isUpdating: true });

        try {
            const importState = await importGuideLocalizationFile(guideId, langCode, file);
            const guide = cloneDeep(state.map[guideId]);

            guide.translationStates = defaultsDeep({ [langCode]: importState }, guide.translationStates);
            commit('setUpdate', { guide });
            commit('setActiveById', { id: guide.id });
            commit('setUpdating', { isUpdating: false });

            return guide;
        } catch (err) {
            commit('setUpdating', { isUpdating: false });
            throw err;
        }
    },
    async clearTranslationStrings ({ commit, state }, { guideId, langCode }) {
        commit('setUpdating', { isUpdating: true });

        try {
            await clearGuideTranslationStrings(guideId, langCode);
            const guide = cloneDeep(state.map[guideId]);

            delete guide.translationStates[langCode];
            if (isEmpty(guide.translationStates)) delete guide.translationStates;

            commit('setUpdate', { guide });
            commit('setActiveById', { id: guide.id });
            commit('setUpdating', { isUpdating: false });
        } catch (err) {
            commit('setUpdating', { isUpdating: false });
            throw err;
        }
    },
    removeGuidesFromMap ({ commit, state }, { guideIds }) {
        const newMap = omit(state.map, guideIds);
        commit('setMap', { map: newMap });
    },
    updateGuidesListUserSettings ({ commit, dispatch }, { guidesListUserSettings }) {
        dispatch(
            'userSettings/updateSubNamespaceSetting',
            {
                name: 'guidesListUserSettings',
                value: JSON.stringify(guidesListUserSettings)
            },
            {
                root: true
            }
        );
        commit('setGuidesListUserSettings', { guidesListUserSettings });
    }
};

export const getters = {
    getGuideById: (state) => (id) => state.map[id],
    list (state, getters, rootState, rootGetters) {
        const apps = rootGetters['apps/appMapForActiveSubscription'];

        const guidesList = Object.values(state.map)
            .filter((guide) => !get(guide, 'attributes.isWatermark'))
            .filter((guide) => !get(guide, 'attributes.resourceCenter'))
            .map((guide) => {
                if (rootState.guideAnalytics.isFetchingViews) return guide;

                const views = rootState.guideAnalytics.views[guide.id] || { numViews: 0, numVisitors: 0 };

                return {
                    ...views,
                    ...guide
                };
            })
            .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

        return addAppToEntityList(guidesList, apps);
    },
    rcModulesList (state, getters, rootState, rootGetters) {
        const rcHomeViewsList = Object.values(state.map).filter((guide) => !!get(guide, 'attributes.resourceCenter'));

        return addAppToEntityList(rcHomeViewsList, rootGetters['apps/appMapForActiveSubscription']);
    },
    rcModulesMap (state, getters) {
        return keyBy(getters.rcModulesList, 'id');
    },
    watermark (state, getters) {
        return Object.values(state.map)
            .filter((guide) => get(guide, 'attributes.isWatermark'))
            .find((guide) => guide.appId === getters.activeGuideApp.id);
    },
    maxPriority (state, getters) {
        const prop = 'attributes.priority';

        return get(maxBy(getters.list, prop), prop, 0);
    },
    activePolls (state, getters) {
        const polls = keyBy(getters.active.polls || [], 'id');

        return flatMap(getters.active.steps, (step) => step.pollIds)
            .map((pollId) => polls[pollId])
            .filter((poll) => poll);
    },
    active (state) {
        // fall back to an empty object in order to rely on vue's reactivity to populate
        // guide details when a user visits the page directly
        // allows us to skip a bunch of `v-if`s and `guide && guide.<prop>`
        return state.map[state.activeId] || {};
    },
    activeGuideApp (state, getters, rootState, rootGetters) {
        const activeGuide = getters.active;

        return rootGetters['apps/appFromGuide'](activeGuide);
    },
    canCopyGuides (state, getters) {
        return getters.list.some((guide) => canCopyGuide(guide));
    },
    canCreateCrossAppGuide (state, getters) {
        return getters.list.some(
            (guide) => canCreateFromScratch(guide.appId) && statelessGuideUtil.isCrossAppGuideEligible(guide)
        );
    },
    getGuidePreviewConfigById: (state, getters, rootState, rootGetters) => (id) => {
        const guide = state.map[id];
        const subscriptionId = rootState.subscriptions.activeId;
        const app = rootGetters['apps/appFromGuide'](guide);
        const isResourceCenter = get(guide, 'attributes.resourceCenter', false);
        const guideType = isResourceCenter ? 'resourcecenter' : 'guide';

        const languages = guide.translationStates
            ? [...Object.keys(guide.translationStates), guide.authoredLanguage]
            : [guide.authoredLanguage];

        const { languageMapping } = GuideLocalization;

        const languageOptions = languages.map((option) => ({
            label: languageMapping[option]?.name || option,
            value: option
        }));

        const language = {
            label: languageMapping[guide.authoredLanguage]?.name || guide.authoredLanguage,
            value: guide.authoredLanguage
        };

        const childGuides = statelessGuideUtil.getGuideIdsFromTaskListGuide(guide);

        const agentConfig = {
            guideId: id,
            stepId: guide?.steps?.[0]?.id,
            subscriptionId,
            guideUrl: `/api/s/${subscriptionId}/${guideType}/${id}/preview`,
            isResourceCenter,
            apiKey: app.apiKey,
            preferredAgentInstallType: app.platform === 'extension' ? 'extension' : 'native',
            isMultiApp: isCrossApp(guide),
            headers: { 'x-adopt-v2': true },
            language,
            languageOptions,
            childGuides
        };

        if (!getters.watermark) return agentConfig;
        if (getters.watermark.state === 'disabled') return agentConfig;

        agentConfig.watermarkConfig = {
            guideId: getters.watermark.id,
            stepId: getters.watermark?.steps?.[0]?.id,
            subscriptionId,
            previewURL: `/api/s/${subscriptionId}/guide/${getters.watermark.id}/preview`
        };

        return agentConfig;
    },
    activeGuidePreviewConfig (_state, getters) {
        const { id } = getters.active;

        return getters.getGuidePreviewConfigById(id);
    }
};

export function fetchGuides (isActiveIsDigitalAdoption) {
    const crossAppGuideParam = isActiveIsDigitalAdoption ? 'includeMultiApp=true&' : '';

    return http
        .get(`/api/s/_SID_/guide?${crossAppGuideParam}omitEditor=classicDesigner&expand=*`)
        .then((res) => res.data);
}

export function fetchGuide (guideId) {
    return http.get(`/api/s/_SID_/guide/${guideId}`).then((res) => res.data);
}

export function fetchGuideTranslations (guideId, langCode) {
    return http.get(`/api/s/_SID_/guide/${guideId}/localization/translations/${langCode}`).then((res) => res.data);
}

export function createGuideTranslations (guideId, langCode) {
    return http.post(`/api/s/_SID_/guide/${guideId}/localization/translations/${langCode}`).then((res) => res.data);
}

export function updateGuideTranslations (guideId, langCode, translations) {
    return http
        .put(`/api/s/_SID_/guide/${guideId}/localization/translations/${langCode}`, translations)
        .then((res) => res.data);
}

export function updateGuide (guide) {
    return http.put(`/api/s/_SID_/guide/${guide.id}`, guide).then((res) => res.data);
}

export function patchGuide (guideId, body) {
    return http.patch(`/api/s/_SID_/guide/${guideId}`, body).then((res) => res.data);
}

export function clearGuide (guideId) {
    return http.post(`/api/s/_SID_/guide/${guideId}/reset`, {}).then((res) => res.data);
}

export function deleteGuide ({ id }) {
    return http.delete(`/api/s/_SID_/guide/${id}`);
}

async function adoptV2Clone (guideId) {
    const response = await http.post(`/api/s/_SID_/guide/${guideId}/adopt/clone`, {
        headers: { 'Content-Type': 'application/json' }
    });

    return response.data;
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
