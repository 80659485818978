<template>
    <!-- eslint-disable vue/no-v-html -->
    <div class="step-preview">
        <div class="preview-loading-state-container">
            <pendo-loading-indicator
                :loading="!isGuidePreviewVisible"
                size="large" />
        </div>
        <div
            ref="preview"
            class="preview"
            :class="{
                'is-mobile-preview': isMobile,
                'is-resource-center-preview': isResourceCenterPreview
            }"
            v-html="previewHtml" />
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { BuildingBlock, BuildingBlockUtils } from '@pendo/services/BuildingBlocks';
import { PendoLoadingIndicator } from '@pendo/components';

const { makeThumbnailPreview, findElementById, renderStep, findBlockByDomId } = BuildingBlock;
const { dpToPx } = BuildingBlockUtils;
const emptyPreview = '<div style="width:100%;height:100%"></div>';

export default {
    name: 'GuideStepPreview',
    components: {
        PendoLoadingIndicator
    },
    props: {
        dom: {
            type: String,
            default: ''
        },
        watermarkDom: {
            type: String,
            default: ''
        },
        announcementsCountMap: {
            type: Object,
            default: null
        },
        resourceCenters: {
            type: Array,
            default: null
        },
        guides: {
            type: Array,
            default: null
        },
        step: {
            type: Object,
            default: null
        },
        isResourceCenterPreview: {
            type: Boolean,
            default: false
        },
        previewLanguage: {
            type: String,
            default: ''
        },
        guideHeight: {
            type: Number,
            default: null
        },
        backgroundColor: {
            type: String,
            default: null
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        hasGuideLocalization: {
            type: Boolean,
            default: false
        },
        debounceTime: {
            type: Number,
            default: 16
        }
    },
    data () {
        return {
            guideWidth: 0,
            guideHeightData: null,
            height: 0,
            width: 0,
            previewHtml: null,
            isGuidePreviewVisible: false,
            imagesInGuide: []
        };
    },
    computed: {
        buildNodeFromJSON () {
            return get(window, 'pendo.buildNodeFromJSON', null);
        }
    },
    watch: {
        guides: {
            async handler (newGuides, oldGuides) {
                const newGuide = newGuides[0];
                const oldGuide = oldGuides[0];
                // Handles the case for announcement guides that display the start date.
                // attributes.date is updated when the start date is changed. For localized guides,
                // this makes sure the preview is updated when this value changes.
                if (
                    !this.isResourceCenterPreview &&
                    get(newGuide, 'attributes.dates') !== get(oldGuide, 'attributes.dates')
                ) {
                    this.buildAndResizePreview();
                }
            }
        },
        guideHeight (newValue) {
            this.guideHeightData = newValue;
        }
    },
    mounted () {
        this.onResize = debounce(() => {
            this.width = this.$el.offsetWidth;
            this.height = this.$el.offsetHeight;
            this.updateGuideSize();
        }, this.debounceTime);

        window.addEventListener('resize', this.onResize);
        window.addEventListener('focus', this.onResize);
        this.buildAndResizePreview();
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('focus', this.onResize);
        this.imagesInGuide.forEach((img) => {
            img.removeEventListener('load', this.handleImageLoad);
        });
    },
    methods: {
        async buildAndResizePreview () {
            this.previewHtml = await this.buildPreview();

            await this.$nextTick();
            this.$refs.preview.style.visibility = 'hidden';
            this.isGuidePreviewVisible = false;
            // If the guide contains images, the guide will remain hidden (and a loading icon is shown)
            // until all the images are loaded. For flexElement to work properly, all images need to
            // be loaded
            this.imagesInGuide = get(window, 'pendo.Sizzle')
                ? window.pendo.Sizzle('img', this.$refs.preview.firstChild)
                : [];
            if (this.imagesInGuide.length) {
                let imgCount = 0;
                this.handleImageLoad = () => {
                    imgCount++;
                    if (imgCount === this.imagesInGuide.length) {
                        this.onResize();
                        this.imagesInGuide = [];
                    }
                };
                this.imagesInGuide.forEach((img) => {
                    // there is a chance the image may already have loaded by the time we get here, if so
                    // skip adding the listener
                    if (img.complete) {
                        this.handleImageLoad();
                    } else {
                        img.addEventListener('load', this.handleImageLoad);
                    }
                });
            } else {
                this.onResize();
            }
        },
        async buildPreview () {
            const { pendo } = window;
            if (!pendo || !this.dom || !this.buildNodeFromJSON) {
                return emptyPreview;
            }

            const createBBTooltip = get(window, 'pendo.BuildingBlocks.BuildingBlockTooltips.createBBTooltip');
            const domJSON = JSON.parse(this.dom);
            const footerJSON = findElementById(domJSON, 'pendo-guide-footer-static');
            if (footerJSON && footerJSON.children) {
                const guideContainer = findElementById(domJSON, 'pendo-guide-container');
                footerJSON.children.forEach((child) => {
                    guideContainer.children.push(child);
                });
                footerJSON.children = [];
            }
            const dom = makeThumbnailPreview(domJSON, {
                isResourceCenterGuide: this.isResourceCenterPreview
            });
            const containerJSON = findElementById(dom, 'pendo-g-');
            const showCaret = get(containerJSON, 'props.data-relative-alignment');

            if (showCaret) {
                const pendoGuideContainer = findElementById(dom, 'pendo-guide-container');

                if (!pendoGuideContainer.props['data-caret-width'] || !pendoGuideContainer.props['data-caret-height']) {
                    pendoGuideContainer.props['data-caret-width'] = '15px';
                    pendoGuideContainer.props['data-caret-height'] = '15px';
                }
            }

            const mockStep = cloneDeep(this.step);
            mockStep.attachEvent = function () {};
            // agent uses `guide.language` in `generateGuideDataTextBlock()` to translate date
            let mockGuides = [...this.guides];
            mockGuides = mockGuides.map((guide) => {
                const dates = this.hasGuideLocalization ? guide.attributes.dates : undefined;
                const language = this.previewLanguage || guide.authoredLanguage;

                return {
                    ...guide,
                    language,
                    attributes: {
                        ...guide.attributes,
                        dates
                    }
                };
            });

            const html = renderStep(
                this.buildNodeFromJSON,
                dom,
                createBBTooltip,
                mockStep,
                mockGuides,
                this.announcementsCountMap
            );
            const htmlParser = new DOMParser();
            const guideDoc = htmlParser.parseFromString(html, 'text/html');
            const guideBase = guideDoc.querySelector("[id^='pendo-g']");
            let { width } = containerJSON.props.style;

            if (!width && this.isMobile) {
                width = '305px';
            } else if (!width || width?.includes('%')) {
                const announcementModuleWidth = await this.getAnnouncementsModuleWidth();
                const defaultWidth = this.guides[0].attributes.isAnnouncement ? announcementModuleWidth : '900px';
                width = this.isResourceCenterPreview ? 'auto' : defaultWidth;
            }

            const minHeight = containerJSON.props.style['min-height'] || '0px';
            const height = this.guideHeightData ? `${this.guideHeightData}px` : null;
            const guideContainer = guideDoc.getElementById('pendo-guide-container');
            const backgroundColor = this.backgroundColor || guideContainer.style['background-color'] || '#FFFFFF';
            const border = guideContainer.style.border || '';
            const boxShadow = guideContainer.style['box-shadow'] || '';
            const borderRadius = this.isResourceCenterPreview ? '3px' : guideContainer.style['border-radius'];
            const closeGuideButton = guideDoc.querySelector('[id^="pendo-close-guide-"]');
            const tooltip = guideDoc.querySelector('.pendo-tooltip-caret');
            const tooltipBorder = guideDoc.querySelector('.pendo-tooltip-caret-border');
            const rightCaretStyles = guideDoc.querySelector("style[type='text/css']");
            // These containers render out empty divs with only class names from this code path intentionally
            // just map over empty div containers with class name _pendo-guide-whatsnew_ and make into placeholders with override styles
            const whatsNewContainers = Array.prototype.slice.call(guideDoc.querySelectorAll('._pendo-guide-whatsnew_'));
            whatsNewContainers.map((container) => {
                const overrideStyles = {
                    height: '75px',
                    backgroundColor: 'rgb(244, 244, 247)',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    color: 'black',
                    fontSize: '20px'
                };
                container.innerText = '< >';

                return Object.assign(container.style, overrideStyles);
            });
            Object.assign(guideContainer.style, {
                border,
                borderRadius,
                boxShadow,
                outline: '',
                position: '',
                width,
                minHeight,
                height,
                backgroundColor
            });

            /*
             * This styling is here to prevent a Safari display issue (from user agent stylesheet) for the close button
             * where a background-color was being set that needs to be overriden to display correctly in the
             * step-preview of App-Engine. The close button displays with the correct background-color in the wild and
             * in Designer.
             */
            if (closeGuideButton) {
                Object.assign(closeGuideButton.style, {
                    'background-color': 'transparent'
                });
            }

            if (tooltip && tooltipBorder) {
                guideContainer.appendChild(tooltip);
                guideContainer.appendChild(tooltipBorder);
            }

            if (rightCaretStyles) {
                guideContainer.prepend(rightCaretStyles);
            }

            if (this.watermarkDom) {
                const parsedWatermarkDom = BuildingBlock.makeThumbnailPreview(JSON.parse(this.watermarkDom));
                const watermarkHtml = BuildingBlock.renderStep(
                    pendo.buildNodeFromJSON,
                    parsedWatermarkDom,
                    createBBTooltip,
                    {
                        attachEvent: () => null
                    }
                );
                const watermarkDoc = new DOMParser().parseFromString(watermarkHtml, 'text/html');
                const watermarkElem = watermarkDoc.getElementById('pendo-watermark');
                const isBottomAligned = guideBase.getAttribute('data-vertical-alignment') === 'Bottom Aligned';
                Object.assign(watermarkElem.style, {
                    position: 'absolute',
                    right: 0,
                    [isBottomAligned ? 'bottom' : 'top']: '100%'
                });
                guideContainer.appendChild(watermarkElem);
            }

            return guideContainer.outerHTML;
        },
        updateGuideSize () {
            if (this.previewHtml === emptyPreview) {
                this.$refs.preview.style.visibility = 'visible';
                this.isGuidePreviewVisible = true;

                return;
            }

            if (get(window, 'pendo.flexElement')) {
                window.pendo.flexElement(this.$el);
            }
            const targetEle = this.$el.querySelector('[data-pendo-grow-height="true"]');
            const { sizeElements } = get(window, 'pendo.BuildingBlocks.BuildingBlockGuides', {});
            if (targetEle && sizeElements) {
                const guide = window.pendo.dom(this.$el.querySelector('#pendo-guide-container'));
                sizeElements(guide);
            }

            const $guideContainer = get(this.$refs, 'preview.firstChild');
            if ($guideContainer) {
                this.guideWidth = $guideContainer.offsetWidth;
                this.guideHeightData = $guideContainer.offsetHeight;
            }
            this.resizePreview();
        },
        resizePreview () {
            const $preview = this.$refs.preview;
            if (!this.width || !this.height || !this.guideWidth || !this.guideHeightData || !$preview) {
                return;
            }
            const $guideContainer = $preview.firstChild;
            const watermarkOffset = this.watermarkDom ? 55 : 0;
            const scale = Math.min(
                this.width / this.guideWidth,
                this.height / (this.guideHeightData + watermarkOffset)
            );
            const newWidth = Math.min(Math.floor(scale * this.guideWidth), this.guideWidth);
            const newHeight = Math.min(Math.floor(scale * this.guideHeightData), this.guideHeightData);
            if (scale < 1) {
                $guideContainer.style.transform = `scale(${scale})`;
            }
            $preview.style.width = `${newWidth}px`;
            $preview.style.height = `${newHeight}px`;
            $preview.style.visibility = 'visible';
            this.isGuidePreviewVisible = true;
        },
        async getAnnouncementsModuleWidth () {
            const defaultAnnouncementModuleWidth = '230px';
            const resourceCenter = this.resourceCenters.find(
                (resourceCenter) => get(resourceCenter, 'draft.homeView.appId') === this.guides[0].appId
            );

            if (!resourceCenter) {
                return defaultAnnouncementModuleWidth;
            }

            const announcementModule = resourceCenter.draft.modules.find((module) => {
                return get(module, 'children', []).includes(this.guides[0].id);
            });

            if (!announcementModule) {
                return defaultAnnouncementModuleWidth;
            }

            let buildingBlocks = get(announcementModule, 'steps[0].buildingBlocks');
            if (!buildingBlocks) {
                return defaultAnnouncementModuleWidth;
            }

            buildingBlocks = JSON.parse(buildingBlocks);
            const widthProp = findBlockByDomId(
                buildingBlocks,
                `pendo-g-${announcementModule.steps[0].id}`
            ).properties.find((prop) => prop.name === 'layout_width');

            if (!widthProp) {
                return defaultAnnouncementModuleWidth;
            }

            return dpToPx(widthProp.value);
        }
    }
};
</script>

<style lang="scss" scoped>
.step-preview {
    position: relative;
}

.preview-loading-state-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.step-preview > .preview {
    left: 50%;
    overflow: visible;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    &.is-resource-center-preview ::v-deep ._pendo-resource-center-view-container,
    &.is-mobile-preview ::v-deep #pendo-guide-container {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2) !important; /* stylelint-disable-line declaration-no-important */
    }

    &.is-mobile-preview {
        ::v-deep #pendo-guide-container {
            margin: 0 !important; /* stylelint-disable-line declaration-no-important */
        }
    }
}

.step-preview > .preview:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.step-preview > .preview ::v-deep #pendo-guide-container {
    transform-origin: left top;
}
</style>
