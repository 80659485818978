<template>
    <pendo-table
        v-pendo-loading:feather="isLoading"
        v-stateless-pendo-table-user-settings-watcher="{
            userSettings: topPagesListUserSettings
        }"
        :data="topPages"
        :columns="columns"
        :max-height="488"
        :default-sort="sort"
        resizable
        condensed
        manage-columns
        title="Top Pages"
        class="top-pages-table"
        @tableSettingsUpdate="handleTableSettingsUpdate">
        <template #headerActions>
            <pendo-button
                data-cy="view-all-pages-button"
                theme="app"
                type="link"
                prefix-icon="page"
                label="View All Pages"
                @click="$emit('routeToPages')" />
        </template>
        <template #empty>
            <div class="top-pages-table--empty">
                <pendo-empty-state
                    :icon="{ 'type': 'page', 'size': 32, 'stroke-width': 1.5 }"
                    title="Pages not Found"
                    description="Try clearing filters. Pages without view data will not appear." />
            </div>
        </template>
        <template #app="{ row }">
            <pendo-app-display :apps="row.app" />
        </template>
    </pendo-table>
</template>

<script>
import { PendoEmptyState, PendoButton, PendoTable, PendoLoading, PendoAppDisplay } from '@pendo/components';
import { formatDataByType } from '@/utils/table-formatters';
import compact from 'lodash/compact';
import {
    mergeDefaultSortWithUserSettings,
    mergeDefaultColumnsWithUserSettings
} from '@/stateless-components/utils/table-user-settings';
import StatelessPendoTableUserSettingsWatcher from '@/stateless-components/directives/StatelessPendoTableUserSettingsWatcher';

export default {
    name: 'TopPages',
    components: {
        PendoButton,
        PendoEmptyState,
        PendoTable,
        PendoAppDisplay
    },
    directives: {
        PendoLoading,
        StatelessPendoTableUserSettingsWatcher
    },
    props: {
        isLoading: {
            type: Boolean,
            required: true
        },
        topPages: {
            type: Array,
            required: true
        },
        showAppUsage: {
            type: Boolean,
            required: true
        },
        activeTimezone: {
            type: String,
            default: ''
        },
        topPagesListUserSettings: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            topPagesUserSettingsName: 'topPages',
            defaultSort: {
                order: 'descending',
                prop: 'pageLoads'
            }
        };
    },
    computed: {
        defaultColumns () {
            return compact([
                {
                    prop: 'displayName',
                    label: 'Page',
                    sortable: true,
                    visible: true,
                    minWidth: 200,
                    schema: 'string',
                    allowResize: true
                },
                this.showAppUsage && {
                    prop: 'app',
                    label: 'App',
                    sortable: true,
                    visible: true,
                    minWidth: 200,
                    schema: 'string',
                    allowResize: true,
                    sortBy: (row) => row.app.displayName
                },
                {
                    prop: 'pageLoads',
                    label: 'Number of Views',
                    sortable: true,
                    visible: true,
                    minWidth: 150,
                    schema: 'integer',
                    allowResize: true,
                    formatter: (row) => formatDataByType('integer', row.pageLoads, this.activeTimezone)
                }
            ]);
        },
        columns () {
            return mergeDefaultColumnsWithUserSettings(this.defaultColumns, this.topPagesListUserSettings);
        },
        sort () {
            return mergeDefaultSortWithUserSettings(this.defaultSort, this.topPagesListUserSettings);
        }
    },
    methods: {
        handleTableSettingsUpdate (newSettings) {
            this.$emit('tableSettingsUpdate', this.topPagesUserSettingsName, newSettings);
        }
    }
};
</script>

<style lang="scss">
.top-pages-table {
    &--empty {
        height: 350px;
    }

    .pendo-table__active-count {
        display: none;
    }
}
</style>
