<template>
    <div class="text-localizer-input">
        <translation-input-row :warning="warnings[transUnitId]">
            <pendo-input
                ref="textarea"
                :value="value"
                type="textarea"
                :rows="1"
                :autosize="false"
                :disabled="disabled"
                @input="onInput">
                <template #prepend>
                    <div class="text-localizer-input__prepend">
                        <pendo-icon
                            :size="16"
                            stroke="#2A2C35"
                            type="align-center" />
                    </div>
                </template>
                <template #suffix>
                    <span
                        v-if="hasChanged"
                        class="text-localizer-edited-marker">
                        (Edited)
                    </span>
                </template>
            </pendo-input>
        </translation-input-row>
    </div>
</template>

<script>
import get from 'lodash/get';
import { PendoInput, PendoIcon } from '@pendo/components';
import TranslationInputRow from './TranslationInputRow';

export default {
    name: 'TextLocalizer',
    components: {
        TranslationInputRow,
        PendoInput,
        PendoIcon
    },
    props: {
        transUnitGroup: {
            type: Object,
            required: true
        },
        transUnitMap: {
            type: Object,
            required: true
        },
        transUnitHeights: {
            type: Object,
            default: () => ({})
        },
        initialTransUnitValues: {
            type: Object,
            default: () => ({})
        },
        valueKey: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        warnings: {
            type: Object,
            default: () => ({})
        }
    },
    emits: ['change', 'changeHeight'],
    computed: {
        transUnitId () {
            return get(this.transUnitGroup, 'blocks[0].id');
        },
        value () {
            return get(this.transUnitMap, `${this.transUnitId}.${this.valueKey}`);
        },
        height () {
            return this.transUnitHeights[this.transUnitId]?.height;
        },
        minHeight () {
            return this.transUnitHeights[this.transUnitId]?.minHeight;
        },
        initialTranslation () {
            const transUnit = this.initialTransUnitValues.find(({ transUnitId }) => transUnitId === this.transUnitId);

            return transUnit.target;
        },
        hasChanged () {
            if (this.valueKey === 'target') {
                return this.value !== this.initialTranslation;
            }

            return false;
        }
    },
    watch: {
        height (newHeight, oldHeight) {
            if (newHeight !== oldHeight) {
                this.resizeTextarea(newHeight);
            }
        }
    },
    async mounted () {
        await this.$nextTick();
        this.$emit('changeHeight', this.transUnitId, this.resizeTextarea(), 'mount');
    },
    methods: {
        resizeTextarea (height = null) {
            const textarea = this.getInputElement();

            // Attempt to autosize but never shrink below the original mount height.
            if (!height) {
                textarea.style.height = 'auto';
                height = textarea.scrollHeight < this.minHeight ? this.minHeight : textarea.scrollHeight;
            }
            textarea.style.height = `${height + 2}px`;

            return height;
        },
        getInputElement () {
            return this.$refs.textarea.$el.querySelector('textarea');
        },
        onInput (value) {
            this.$emit('changeHeight', this.transUnitId, this.resizeTextarea(), 'input');
            this.$emit('change', this.transUnitId, value);
        }
    }
};
</script>

<style lang="scss" scoped>
.text-localizer-input {
    ::v-deep .pendo-input--textarea .pendo-input__field {
        min-height: inherit;

        .pendo-input__suffix {
            align-self: start;
            padding: 8px 8px;
        }
    }

    &__prepend {
        padding-top: 10px;
        height: 100%;
    }
}
</style>
