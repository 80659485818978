<template>
    <pendo-drawer
        :visible="visible"
        :title="title"
        size="fullscreen"
        @close="onClose">
        <template #body>
            <div class="guide-audit-body">
                <div class="guide-audit-details-container">
                    <guide-audit-details
                        :is-mobile-app="isMobileApp"
                        :segments="segments"
                        :selected-revision-subtitle="selectedRevisionSubtitle"
                        :previous-revision-subtitle="previousRevisionSubtitle"
                        :active-timezone="activeTimezone"
                        :show-previous-revision="showPreviousRevision"
                        :selected-revision="selectedRevision"
                        :previous-revision="previousRevision"
                        :loading="loading" />
                    <guide-audit-step-details
                        v-if="!loading"
                        :active-timezone="activeTimezone"
                        :selected-revision-subtitle="selectedRevisionSubtitle"
                        :previous-revision-subtitle="previousRevisionSubtitle"
                        :show-previous-revision="showPreviousRevision"
                        :selected-revision="selectedRevision"
                        :previous-revision="previousRevision" />
                </div>
                <guide-audit-revision-list
                    :guide="selectedGuide"
                    :revisions="revisions"
                    :selected-revision="selectedRevision"
                    :active-timezone="activeTimezone"
                    :loading="loading"
                    :current-user="currentUser"
                    @updateDateFilter="updateDate"
                    @updateSelection="updateSelection" />
            </div>
        </template>
    </pendo-drawer>
</template>

<script>
import GuideAuditStepDetails from './GuideAuditStepDetails';
import GuideAuditRevisionList from './GuideAuditRevisionList';
import GuideAuditDetails from './GuideAuditDetails';
import get from 'lodash/get';
import isString from 'lodash/isString';
import moment, { DATE_FORMAT, convertToSubscriptionTimezone } from '@/utils/moment';
import { areRevisionsEqual } from './guide-audit-utils';
import { PendoDrawer } from '@pendo/components';

export default {
    name: 'GuideAuditDrawer',
    components: {
        PendoDrawer,
        GuideAuditRevisionList,
        GuideAuditStepDetails,
        GuideAuditDetails
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        selectedGuide: {
            type: Object,
            required: true
        },
        revisions: {
            type: Array,
            default: () => []
        },
        selectedRevision: {
            type: Object,
            default: undefined
        },
        activeTimezone: {
            type: String,
            required: true
        },
        segments: {
            type: Object,
            default: () => ({})
        },
        isMobileApp: {
            type: Boolean,
            default: false
        },
        currentUser: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            hasHistoryLoadedEver: false,
            selectedRevisionIndex: 0
        };
    },
    computed: {
        showPreviousRevision () {
            return !!(this.previousRevision && this.previousRevision.lastUpdatedByUser);
        },
        previousRevision () {
            if (!this.selectedRevision) return undefined;
            const previousIndex =
                this.revisions.findIndex((curr) => areRevisionsEqual(curr, this.selectedRevision)) + 1;

            return this.revisions[previousIndex];
        },
        title () {
            return `Edit history for ${this.selectedGuide.name}`;
        },
        selectedRevisionSubtitle () {
            const contentLastUpdatedDate = this.getFormattedLastUpdateDate(this.selectedLastUpdateTimestamp);

            return this.getSubtitle(contentLastUpdatedDate, this.selectedRevisionLastUpdateAuthor);
        },
        previousRevisionSubtitle () {
            const contentLastUpdatedDate = this.getFormattedLastUpdateDate(this.previousLastUpdateTimestamp);

            return this.getSubtitle(contentLastUpdatedDate, this.previousRevisionLastUpdateAuthor);
        },
        selectedRevisionLastUpdateAuthor () {
            return get(this, 'selectedRevision.lastUpdatedByUser.username', '');
        },
        previousRevisionLastUpdateAuthor () {
            return get(this, 'previousRevision.lastUpdatedByUser.username', '');
        },
        previousLastUpdateTimestamp () {
            return get(this, 'previousRevision.lastUpdatedAt', 0);
        },
        selectedLastUpdateTimestamp () {
            return get(this, 'selectedRevision.lastUpdatedAt', 0);
        }
    },
    methods: {
        onClose () {
            this.$emit('close');
        },
        updateDate (dateRange) {
            const startTimeStamp = moment(dateRange.value.start).valueOf();
            const endTimeStamp = moment(dateRange.value.end).valueOf();
            this.$emit('updateDateFilter', startTimeStamp, endTimeStamp + 1);
        },
        updateSelection (revision) {
            this.$emit('updateSelection', revision);
        },
        getSubtitle (contentLastUpdatedDate = '', contentLastUpdateAuthor = '') {
            if (!isString(contentLastUpdatedDate) || contentLastUpdatedDate === '') return '';
            const authorSubtitle = contentLastUpdateAuthor !== '' ? `by ${contentLastUpdateAuthor}` : '';

            return `${contentLastUpdatedDate} ${authorSubtitle}`.trim();
        },
        getFormattedLastUpdateDate (timestamp = 0) {
            if (!timestamp) return '';

            return convertToSubscriptionTimezone(this.activeTimezone, timestamp).format(DATE_FORMAT.full);
        }
    }
};
</script>
<style scoped lang="scss">
.guide-audit-body {
    display: flex;
    padding: 32px;
    width: 100%;
    background: transparent;
    height: auto;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;

    .guide-audit-details-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 1400px;
    }
}
</style>

<style lang="scss">
.guide-audit-body {
    .step-preview {
        height: 350px;
        min-height: 350px;
        max-height: 350px;
    }
}
</style>
